<template>
  <div class="404">
    <h1>404 Not Found.</h1>
  </div>
</template>

<script >
export default {
  name: 'NotFound'
}
</script>

<style lang="scss" scoped></style>
